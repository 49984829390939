import { template as template_3e2d24760c204745b73a2d709a78c66b } from "@ember/template-compiler";
const WelcomeHeader = template_3e2d24760c204745b73a2d709a78c66b(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
